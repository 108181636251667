import styles from "./App.module.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
//import Header from "../Header/Header";
import Input from "components/Input/Input";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Puzzle from "components/Puzzle/Puzzle";

function App() {
  const [dbPuzzles, setDbPuzzles] = useState<any[]>([]);
  const [dbProblems, setDbProblems] = useState<any[]>([]);
  const [input, setInput] = useState<string>("");
  let baseURL = "";
  let strPort = process.env.PORT || 3001;
  if (process.env.NODE_ENV === "production") {
    baseURL = "https://app.cryptochris.be";
  } else {
    baseURL = "http://192.168.1.99:" + strPort;
  }

  //console.log("envPort:" + process.env.PORT);
  //console.log("envProd:" + process.env.NODE_ENV);
  //console.log("baseurl: " + baseURL);


  // We fetch the puzzles data from the database here on render
  // and place the data in the variable 'dbPuzzles'
  useEffect(() => {
    Axios.get(`${baseURL}/table/puzzles`).then((response) => {
      setDbPuzzles(response.data);
    });
  }, [baseURL]);

  // We fetch the problems data from the database here on render
  // and place the data in the variable 'dbProblems'
  useEffect(() => {
    Axios.get(`${baseURL}/table/problems`).then((response) => {
      setDbProblems(response.data);
    });
  }, [baseURL]);

  // We get the input from what the user selected from the Input.tsx here.
  const handleInput = (input: string): void => {
    setInput(input);
  };

  if (dbProblems.length == 0 || dbPuzzles.length == 0) {
    return <div></div>
  }


  return (
    <BrowserRouter>
      <div className={styles.App}>
        <Switch>
          <Route exact path="/puzzle/:key">
            <Input dataPuzzle={dbPuzzles} onInputChange={handleInput} />
            <Puzzle
              dataProb={dbProblems}
              dataPuzzle={dbPuzzles}
              inputId={input}
            />
          </Route>
          <Route path="/">
            <Input dataPuzzle={dbPuzzles} onInputChange={handleInput} />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}
export default App;
