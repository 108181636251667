import styles from "./Puzzle.module.css";
import { ProblemTable, PuzzleTable } from "types";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { Modal } from "@daypilot/modal";
import { useState } from "react";


interface PuzzleProps {
  dataProb: ProblemTable[];
  dataPuzzle: PuzzleTable[];
  inputId: string;
}


export interface PuzzleParams {
  key: string
}

const Puzzle = ({ dataProb, dataPuzzle }: PuzzleProps) => {

  const params = useParams<PuzzleParams>();
  const [easy, setEasy] = useState<boolean>(localStorage.getItem("level") === "easy");   // gemakkelijkere versie

  let inputId: string = params.key;

  // CONSTANTS AND VARIABLES ===========================================================
  let alphabet: string[] = [
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",];
  let arrPuzzelData: any[] = []; // juiste puzzeldata voor de actieve puzzel
  dataPuzzle
    .filter((row: PuzzleTable) => row.id === inputId)
    .map((p: PuzzleTable) => {
      return arrPuzzelData.push(p);
    });
  let arrProbData: any[] = []; // juiste problemsdata voor de actieve puzzel
  dataProb
    .filter((row: ProblemTable) => row.puzzles_id === inputId)
    .map((p: ProblemTable) => {
      return arrProbData.push(p);
    });

  let rowCounterClues = -1;
  let rowCounterCanvas = -1;
  let preDivs: JSX.Element[] = []; // onnodige divs
  let inputDivs: JSX.Element[] = []; // inputs in raster
  let maskDivs: JSX.Element[] = [];
  //let focusElements: any[] = [];    // focused elements
  let intOnDivs = 0; // onnodige divs
  let intLengte = 0; // woordlengte
  let intLengteVorigeRij = 0;
  let strId = "";
  let arrMasker: string[] = [];
  const intLettersUsed = arrPuzzelData[0].number_lettersused; //aantal verschillende letters gebruikt in de cijfervakjes
  const intVertPos = arrPuzzelData[0].y_word_position; // positie verticale woord
  const xas = arrPuzzelData[0].x_axis   // maximale breedte
  const opmerking = arrPuzzelData[0].visible_comment   // weer te geven opmerking
  let blnVert = false; // gebruikt in JSX om data-vert toe te voegen
  let blnVertClicked = false; // klik op Verticaal-button
  let blnLevels = (arrPuzzelData[0].type === 'levels')   // multilevel
  const maxLength = 1; // max 1 letter toegelaten voor invoer
  let arrLettersUsed = new Array(intLettersUsed).fill("");
  let arrAnswers: string[] = []; // alle ingevulde oplossingen in woordvorm
  let arrPartialSol = new Array(intLettersUsed).fill("");
  let arrAllInput: string[] = []; // alle inputs (voor bijhouden voortgang in localstorage)
  let arrStatuses: string[] = ["started", "partial", "full", "correct",]; // status (voor localstorage)
  let lsStatus = localStorage.getItem(`${inputId}Status`); // status actieve puzzel

  let baseURL = "";
  let strPort = process.env.PORT || 3001;
  if (process.env.NODE_ENV === "production") {
    baseURL = "https://app.cryptochris.be";
  } else {
    baseURL = "http://192.168.1.99:" + strPort;
  }



  // INITIALIZE =================================================================
  document.querySelectorAll("input").forEach((input) => (input.value = "")); // alle inputs leegmkaken
  unFocus(); // unfocus all
  document
    .querySelectorAll(`.${styles.foutantw}`)
    .forEach((e: any) => e.classList.remove(styles.foutantw)); // rood verwijderen
  if (lsStatus) {
    // geen nieuwe puzzel
    setTimeout(() => {
      // nog aanpassen: fillinputs mag pas gebeuren na render, settimeout wegwerken
      fillInputLS(); // inputs uit localstorage halen en alfabet updaten
    }, 100);
  } else {
    setTimeout(() => {
      // nog aanpassen:  mag pas gebeuren na render, settimeout wegwerken
      updateAlfabet(); // voor nieuwe puzzel enkel alfabetbalk updaten
    }, 100);
  }
  let canvasBreedte = ""    // voor mobile devices
  if (xas < 14) {
    canvasBreedte = "breedsteCanvas"
  } else if (xas < 17) {
    canvasBreedte = "brederCanvas"
  }



  // HELPERS =======================================================================================
  // HELPERS HELPERS HELPERS HELPERS HELPERS HELPERS HELPERS HELPERS HELPERS HELPERS HELPERS HELPERS
  // HELPERS =======================================================================================

  function fillInputLS() {
    // inputs uit localstorage halen en alfabetbalk updaten
    let lsInputTmp: any = localStorage.getItem(`${inputId}Input`);
    if (lsInputTmp) {
      // input gevonden in localstorage
      let arrLsInput: string[] = []; // input uit LocalStorage
      lsInputTmp = lsInputTmp
        .replace(/["']/g, "")
        .replace("[", "")
        .replace("]", ""); // ongewenste karakters []" weghalen
      arrLsInput = lsInputTmp.split(","); // van string array maken
      document
        .querySelectorAll(`[data-irij]`)
        .forEach((e: any, index: number) => (e.value = arrLsInput[index])); // inputvelden in raster invllen
      document
        .querySelectorAll(`[id^="l"]`)
        .forEach((e: any, index: number) => {
          // letterbalk aanvullen
          let el: any = document.querySelector(`[data-mask='${index + 1}`);
          e.value = el!.value;
        });
    }
    updateAlfabet();
  }

  function getAnswers() {
    // array met alle ingevulde oplossingen in woordvorm opbouwen
    arrAnswers = [];

    arrProbData.map((a, index) => {
      const arrLetters = document.querySelectorAll(
        `[data-irij='${index + 1}']`
      );
      let woord = "";
      arrLetters.forEach((letter: any) => {
        woord = woord + letter.value;
      });
      arrAnswers.push(woord);
    });
    return arrAnswers;
  }

  function updateAlfabet() {
    //alfabetbalk updaten
    document
      .querySelectorAll(`.${styles.pixelAlfabet}`)
      .forEach((e: any) => (e.style.visibility = "visible")); // eerst alle letters tonen
    const arrLetterBalk = document.querySelectorAll(`[id^="l"]`); // vakjes uit letterbalk
    arrLetterBalk.forEach((e: any) => {
      if (e.value !== "") {
        // is vakje uit letterbalk ingevuld?
        document.getElementById(`${e.value.toUpperCase()}`)!.style.visibility =
          "hidden"; // overeenkomstige letter uit alfabetbalk selecteren en onzichtbaar maken
      }
    });
  }

  function unFocus() {        // geen enkele focus

    //focusElements.forEach(focusElement => focusElement.classList.remove(styles.focus))    // werkt nog niet goed
    document    // 
      .querySelectorAll(`.${styles.focus}`)
      .forEach((e) => e!.classList.remove(styles.focus));


    //focusElements = []
  }

  function handleSave() {
    // vooortgang en status opslaan in LocalStorage
    arrAllInput = []; // array eerst leegmaken
    document
      .querySelectorAll(`[data-irij]`)
      .forEach((e: any) => arrAllInput.push(e.value.toUpperCase())); // alle rasterinputs in array steken
    localStorage.setItem(inputId + "Input", JSON.stringify(arrAllInput)); // en wegschrijven in localstorage

    if (!lsStatus) {
      //  bij nieuwe puzzel lastPuzzle en status wegschrijven
      localStorage.setItem(inputId + "Status", arrStatuses[0]);
      localStorage.setItem("lastPuzzle", inputId);
    }
  }

  function handleSaveThis() {
    // opslaan van 1 vakje nog uitwerken
    console.log("ok")
  }

  function nextSibling(
    e: any,
    vertical: boolean,
    stayOnRow: boolean
  ): HTMLInputElement {
    // zoekt volgende element in de navigatie, eventueel ook volgende rij
    let el: any = null;
    if (blnVertClicked) {
      // for entering the vertical word
      el = document.querySelector(
        `.${styles.myInputVert}[data-irij='${parseInt(e.target.dataset.irij) + 1
        }']`
      )!;
    } else if (vertical) {
      // naar 1e element van volgende rij indien parameter vertical is true
      el = document.querySelector(
        `[data-irij='${parseInt(e.target.dataset.irij) + 1}'][data-col='1']`
      )!;
    } else {
      el = document.querySelector(
        `[data-irij='${e.target.dataset.irij}'][data-col='${parseInt(e.target.dataset.col) + 1
        }']`
      )!; // volgende kolom
      if (el == null) {
        if (!stayOnRow) {
          el = document.querySelector(
            `[data-irij='${parseInt(e.target.dataset.irij) + 1}'][data-col='1']`
          )!; // naar 1e element van volgende rij
        }
      }
    }
    return el as HTMLInputElement;
  }

  function prevSibling(
    e: any,
    vertical: boolean,
    stayOnRow: boolean
  ): HTMLInputElement {
    // zoekt vorige element in de navigatie, eventueel ook vorige rij
    let el: any = null;
    if (blnVertClicked) {
      // for entering the vertical word
      el = document.querySelector(
        `.${styles.myInputVert}[data-irij='${parseInt(e.target.dataset.irij) - 1
        }']`
      )!;
    } else if (vertical) {
      el = document.querySelector(
        `[data-irij='${parseInt(e.target.dataset.irij) - 1}'][data-col='1']`
      )!;
    } else {
      // previous column
      el = document.querySelector(
        `[data-irij='${e.target.dataset.irij}'][data-col='${parseInt(e.target.dataset.col) - 1
        }']`
      )!;
      if (el == null) {
        // otherwise last input from previous row
        if (!stayOnRow) {
          el = document.querySelector(
            `[data-irij='${parseInt(e.target.dataset.irij) - 1}'][data-col='${e.target.dataset.lengte
            }']`
          )!;
        }
      }
    }
    return el as HTMLInputElement;
  }

  function eolSibling(e: any): HTMLInputElement {
    // voor END-toets
    let es = document.querySelectorAll(
      `[data-irij='${e.target.dataset.irij}']`
    );
    let el: any = null;
    es.forEach((e: any) => {
      el = e;
    });
    return el as HTMLInputElement;
  }

  function bolSibling(e: any): HTMLInputElement {
    // voor HOME-toets
    let el = document.querySelector(`[data-irij='${e.target.dataset.irij}']`);
    return el as HTMLInputElement;
  }

  const showPartialAnswers = async () => {
    //GEDEELTELIJKE OPLOSSING GEVEN

    const resp = await Axios.post(
      `${baseURL}/partialanswers`,
      {
        // array met oplossing A opvullen op basis van vraag/antwoord met db
        id: inputId,
      }
    );
    console.log(resp)
    if (resp.data.message === "NOK") {
      Modal.alert("Oplossing A is (nog) niet beschikbaar");
    }
    else {


      let objResp = resp.data[0];
      for (const property in objResp) {
        if (objResp[property] > 0) {
          arrPartialSol[objResp[property]] = property;
        }
      }
      let es: any = document.querySelectorAll(`[id^="l"]`); // letterbalk
      es.forEach((e: any, index: number) => {
        e.value = arrPartialSol[index + 1];
      });

      es = []; // cijfervakjes in raster
      document.querySelectorAll(`[data-mask]`).forEach((e: any) => {
        // selectie in 2 stappen omdat (`[data-mask]:not([data-mask=""]`) niet werkt op iOS
        if (e.dataset.mask !== "") {
          es.push(e);
        }
      });
      es.forEach((e: any) => (e.value = arrPartialSol[e.dataset.mask]));

      handleSave();
      updateAlfabet();
      let status = localStorage.getItem(inputId + "Status");

      if (status !== "full" && status !== "partial" && status !== "correct") {
        const resp = Axios.post(`${baseURL}/puzzles/solutiona`, { id: inputId, });   //  teller in db verhogen
        localStorage.setItem(inputId + "Status", arrStatuses[1]);

      }
    }
  };

  const showFullAnswers = async () => {
    //VOLLEDIGE OPLOSSING
    const resp = await Axios.post(`${baseURL}/answers`, {
      id: inputId,
    });

    console.log(resp)
    if (resp.data.message === "NOK") {
      Modal.alert("Oplossing B is (nog) niet beschikbaar");
    }
    else {

      let objResp = resp.data;
      document.querySelectorAll(`[data-irij]`).forEach((e: any) => {
        // alle rasterinputs
        e.value = objResp[e.dataset.irij - 1].answer.substring(
          e.dataset.col - 1,
          e.dataset.col
        );
        if (e.dataset.mask !== "") {
          // letterbalk aanvullen
          let le: any = document.getElementById(`l${e.dataset.mask}`);
          le.value = e.value;
        }
      });
      handleSave();
      updateAlfabet();
      let status = localStorage.getItem(inputId + "Status");
      if (status !== "full" && status !== "correct") {
        const resp = Axios.post(`${baseURL}/puzzles/solutionb`, { id: inputId, });   //  teller in db verhogen
        localStorage.setItem(inputId + "Status", arrStatuses[2]);

      }
      localStorage.setItem(inputId + "Status", arrStatuses[2]); // status op full
    }
  };




  //EVENT HANDLING =================================================================
  //EVENT HANDLING =================================================================

  function handleInputFocus(e: any) {
    // FOCUS RASTERINPUT
    unFocus(); // unfocus all elements
    const par = document.getElementById(e.target.dataset.irij); // focus clue
    par!.classList.add(styles.focus);
    //focusElements.push(par)
    if (blnVertClicked === false) {
      const rowInputs = document.querySelectorAll(
        `[data-irij='${e.target.dataset.irij}']`
      ); // inputs van dezelfde rij focussen
      rowInputs.forEach((rowInput) => {
        rowInput!.parentElement!.parentElement!.classList.add(styles.focus);
        //focusElements.push(rowInput!.parentElement!.parentElement)
      });

      if (e.target.dataset.mask) {
        const maskInputs = document.querySelectorAll(
          `[data-mask='${e.target.dataset.mask}']`
        ); //  inputs met zelfde masker focussen
        maskInputs.forEach((maskInput) => {
          maskInput!.parentElement!.parentElement!.classList.add(styles.focus)
          //focusElements.push(maskInput!.parentElement!.parentElement)
        });
      }
    }

    const clueabove: any = document.getElementById("clueabove");
    const cluebelow: any = document.getElementById("cluebelow");
    const clue: any = document.getElementById(`c${e.target.dataset.irij}`);
    const letter = alphabet[e.target.dataset.irij - 1]
    clueabove.innerHTML = letter + "&nbsp;&nbsp;" + clue.innerHTML;
    cluebelow.innerHTML = letter + "&nbsp;&nbsp;" + clue.innerHTML;

    //e.target.select()       // select input - werkt niet op mobiele devices
    e.target.setSelectionRange(0, e.target.value.length); // select input - werkt op alle devices maar triggert op mobiele klembord-popup
  }

  function handleInputChange(e: any) {                      // INPUT CHANGE
    const { value } = e.target;
    if (value.length > maxLength) {         // nodig voor Android
      e.target.value = value.substring(0, 1);
    } else if (value.length === maxLength) {
      // niet blanco
      if (e.target.value.toUpperCase() >= "A" && e.target.value.toUpperCase() <= "Z") {
        // geldige invoer
        handleSave();
        let el: any = document.getElementById(`b${e.target.dataset.irij}`); // eventueel rood van vorige controle weghalen
        if (el.classList.contains(`${styles.foutantw}`)) {
          el.classList.remove(styles.foutantw); // rood weg bij beginletter
          document
            .querySelectorAll(`[data-irij='${e.target.dataset.irij}']`)
            .forEach((el) => el.classList.remove(styles.foutantw)); // rood weg bij inputs van deze rij
          document
            .querySelector(`[data-id='o${e.target.dataset.irij}']`)!
            .classList.remove(styles.foutantw);
        }


        if (nextSibling(e, false, true) !== null) {
          //naar volgende input
          nextSibling(e, false, true).focus();
        }

      } else {
        e.target.value = "";    //// ongeldig karakter wissen
      }
    }
  }



  function handleInputBlur(e: any) {
    // INPUT BLUR
    if (e.target.dataset.mask) {
      // inputs met zelfde masker invullen of leegmaken
      const maskInputs = document.querySelectorAll(
        `[data-mask='${e.target.dataset.mask}']`
      ); // select inputs with same mask
      maskInputs.forEach((maskInput: any) => {
        maskInput.value = e.target.value;
      });
      let el: any = document.getElementById("l" + e.target.dataset.mask); // letterbalk aanpassen
      el!.value = e.target.value;
      updateAlfabet();
    }
  }


  function handleWissen() {
    // PUZZLE WISSEN
    Modal.confirm("Wil je het raster en de opgeslagen voortgang wissen?", { okText: "Ok", cancelText: "Annuleren" }).then(function (args) {

      if (args.result === "OK") {
        document
          .querySelectorAll("input")
          .forEach((allInput) => (allInput.value = "")); // inputs uit raster en letterbalk leegmaken
        document.getElementById("btnVert")?.classList.remove(styles.btnDisabled); // verticaal-button uitzetten
        document
          .querySelectorAll(`.${styles.foutantw}`)
          .forEach((e: any) => e.classList.remove(styles.foutantw)); // rood verwijderen
        localStorage.removeItem(`${inputId}Status`); // localstorage clearen
        localStorage.removeItem(`${inputId}Input`);
      }
      unFocus();
      updateAlfabet();
    })
  }

  const handleControle = async () => {
    // OPLOSSING CONTROLEREN
    getAnswers(); // ingevulde woorden
    let blnAllCorrect = true; // default = alle oplossingen juist
    let arrAnswersChecked = new Array(arrProbData.length).fill(false);
    let x = 0;

    for (let item of arrAnswers) {
      let resp = await Axios.post(`${baseURL}/problems`, {
        // ingegeven oplossing naar server overbrengen
        id: arrProbData[x].id,
        puzzles_id: inputId,
        answer: item,
      });
      arrAnswersChecked[x] = resp.data.correct; // return = juist of fout
      if (!resp.data.correct) {
        // fout antwoord
        document.getElementById(`b${x + 1}`)?.classList.add(styles.foutantw); // letter voor clue in rood
        document
          .querySelector(`[id*= o${x + 1}-][id$='_']`)
          ?.classList.add(styles.foutantw); // letter voor oplossing in rood
        document
          .querySelectorAll(`[data-irij='${x + 1}']`)
          .forEach((e) => e.classList.add(styles.foutantw)); // letters foute woorden in rood
        blnAllCorrect = false;
      } else {
        // bij juiste oplossing eventueel rood van vorige controle verwijderen
        document.getElementById(`b${x + 1}`)?.classList.remove(styles.foutantw);
        document
          .querySelector(`[id*= o${x + 1}-][id$='_']`)
          ?.classList.remove(styles.foutantw);
        document
          .querySelectorAll(`[data-irij='${x + 1}']`)
          .forEach((e) => e.classList.remove(styles.foutantw));
      }
      x = x + 1;
    }
    if (blnAllCorrect) {
      // alles juist?
      if (localStorage.getItem(inputId + "Status") !== "full") {
        // geen full solution gevraagd
        if (localStorage.getItem(inputId + "Status") !== "correct") {
          localStorage.setItem(inputId + "Status", arrStatuses[3]);
          const resp = await Axios.post(`${baseURL}/puzzles/correct`, { id: inputId, });   //  teller in db verhogen
        }
        Modal.alert(arrPuzzelData[0].message_correct); // custom felicitatie uit databank
      } else {
        // wel full solution gevraagd
        Modal.alert("Je hebt eerst de volledige oplossing gevraagd; anders had ik je gefeliciteerd ;-)");
      }
    } else {
      // fouten aanwezig
      const resp = await Axios.post(`${baseURL}/puzzles/checked`, { id: inputId, });   //  teller in db verhogen
      Modal.alert("De foute oplossingen staan in het rood.");
    }
  };


  function handleOplossingA() {
    // PUZZLE WISSEN
    Modal.confirm("Wil je alle cijfervakjes laten invullen?", { okText: "Ok", cancelText: "Annuleren" }).then(function (args) {
      if (args.result === "OK") {
        showPartialAnswers()
      }
    })
  }


  function handleOplossingB() {
    // PUZZLE WISSEN
    Modal.confirm("Wil je alle vakjes laten invullen?", { okText: "Ok", cancelText: "Annuleren" }).then(function (args) {
      if (args.result === "OK") {
        showFullAnswers()
      }
    })
  }


  function handleClueClick(e: any) {
    // CLUECLICK
    const par = document.getElementById(e.target.id)!.parentElement; // focus op eerste vakje van die rij
    document.getElementById(`i${par!.id}-1`)?.focus();
  }

  function handleVertClick(e: any) {
    // KLIK VERTICAAL-BUTTON
    blnVertClicked = !blnVertClicked;
    if (blnVertClicked) {
      // verticale navigatie?
      e.target.classList.add(styles.btnDisabled); // disabled uitzetten
      let el: any = document.querySelector(`.${styles.myInputVert}`); // eerste verticaal vakje selecteren
      el.focus();
    } else {
      e.target.classList.remove(styles.btnDisabled); // button disablen
    }
  }

  function handleEasy(e: any) {
    setEasy(!easy)
    if (!easy) {
      e.target.classList.add(styles.btnDisabled); // button disablen
      localStorage.setItem("level", "easy");
    } else {
      e.target.classList.remove(styles.btnDisabled); // disabled uitzetten
      localStorage.setItem("level", "normal");
    }


  }


  function handleKeyPress(e: any) {
    // gaat nog fout als het volgend vakje al dezelfde letter bevat als de nieuwe waarde die in het huidige vakje ingetypt wordt: dan 2x change
    //console.log("up key: " + e.key + " - code: " + e.code)
    switch (e.key) {
      case "ArrowRight": {
        (nextSibling(e, false, false) as HTMLElement)?.focus();
        break;
      }
      case "ArrowLeft": {
        (prevSibling(e, false, false) as HTMLElement)?.focus();
        break;
      }
      case "ArrowUp": {
        (prevSibling(e, true, false) as HTMLElement)?.focus();
        break;
      }
      case "ArrowDown": {
        (nextSibling(e, true, false) as HTMLElement)?.focus();
        break;
      }
      case "Backspace": {
        (prevSibling(e, false, true) as HTMLElement)?.focus();
        break;
      }
      case "End": {
        (eolSibling(e) as HTMLElement)?.focus();
        break;
      }
      case "Home": {
        (bolSibling(e) as HTMLElement)?.focus();
        break;
      }
      case "Enter": {
        (nextSibling(e, true, false) as HTMLElement)?.focus();
        break;
      }
      case "NumpadEnter": {
        (nextSibling(e, true, false) as HTMLElement)?.focus();
        break;
      }

    }

  }


  function handleLetterbalkClick(e: any) {
    unFocus();

    const maskInputs = document.querySelectorAll(
      `[data-mask='${e.target.dataset.masklb}']`
    ); // select inputs with same mask
    maskInputs.forEach((maskInput) =>
      maskInput!.parentElement!.parentElement!.classList.add(styles.focus)
    );
  }

  return (
    <>{/*Visible comment*/}
      <div key="Opmerking"
        className={styles.opmerking}
        dangerouslySetInnerHTML={{ __html: (opmerking) }}>

      </div>

      {/*CLUES CONTAINER     =================================*/}
      <div key="keycontainer" className={styles.container}>
        <div key="keyclues" className={styles.cluesContainer}>
          {arrProbData.map((rij, index) => {
            rowCounterClues++;
            return (
              <div
                id={(rowCounterClues + 1).toString()}
                className={styles.clueMainDiv}
                key={index}
              >
                <div
                  id={"b" + (rowCounterClues + 1).toString()}
                  className={styles.clueDivLetter}
                  key={"l" + (rowCounterClues + 1).toString()}
                  onClick={handleClueClick}
                >
                  {alphabet[rowCounterClues]}{" "}
                </div>
                <div
                  id={"c" + (rowCounterClues + 1).toString()}
                  className={styles.clueDivClue}
                  key={"c" + (rowCounterClues + 1).toString()}
                  onClick={handleClueClick}
                  dangerouslySetInnerHTML={{ __html: (easy && blnLevels ? rij.clue2 : rij.clue) }}
                ></div>
              </div>
            );
          })}
        </div>

        {/*CANVAS CONTAINER     =================================*/}
        <div className={styles.canvasContainer + " " + styles[canvasBreedte]}>
          <div id="clueabove" key="clueabove" className={styles.extraMobile}></div>
          {arrProbData.map((rij, index) => {
            rowCounterCanvas++;
            intOnDivs = rij.unused_cols + 1;
            preDivs = [];
            inputDivs = [];
            maskDivs = [];
            intLengte = rij.length_word;
            strId = "";
            arrMasker = arrProbData[rowCounterCanvas].mask.split(";");

            //array onnodige divs
            for (let o = 1; o <= intOnDivs; o++) {
              strId = `o${(rowCounterCanvas + 1).toString()}-${o.toString()}`;
              if (o === intOnDivs) {
                preDivs.push(
                  <div
                    id={strId + "_"}
                    key={strId}
                    className={styles.pixel}
                    data-id={`o${(rowCounterCanvas + 1).toString()}`}
                    dangerouslySetInnerHTML={{
                      __html: alphabet[rowCounterCanvas] + "&nbsp;",
                    }}
                  />
                );
              } else {
                preDivs.push(
                  <div id={strId} key={strId} className={styles.pixel} />
                );
              }
            }

            //array inputs + masks
            for (let i = 1; i <= intLengte; i++) {
              strId = `i${(rowCounterCanvas + 1).toString()}-${i.toString()}`;
              blnVert = i + intOnDivs - 1 === intVertPos;
              inputDivs.push(
                <input
                  type="text"
                  autoComplete="off"
                  maxLength={1}
                  id={strId}
                  data-irij={rowCounterCanvas + 1}
                  data-col={i}
                  data-lengte={intLengteVorigeRij}
                  data-mask={arrMasker[i - 1].replace("x", "")}
                  className={blnVert ? styles.myInputVert : styles.myInput}
                  key={strId}
                  data-vert={blnVert}
                  onFocus={handleInputFocus}
                  onInput={handleInputChange}
                  onKeyUp={handleKeyPress}
                  onBlur={handleInputBlur}
                />
              );
            }
            intLengteVorigeRij = intLengte;
            for (let m = 0; m < intLengte; m++) {
              maskDivs.push(
                <div
                  id={"m" + m.toString()}
                  key={"m" + m.toString()}
                  className={styles.divMasker}
                  children={<span id="spid">{arrMasker[m].replace("x", "")}</span>}
                />
              );
            }

            return (
              <div className={styles.mainInputContainer} key={index}>
                <div key="key1">
                  {preDivs.map((preDiv, index) => {
                    return (
                      <div key={index} className={styles.onnodigeInputs}>
                        {preDiv}
                      </div>
                    );
                  })}

                  {inputDivs.map((letterDiv, index) => {
                    return (
                      <div key={index} className={styles.pixel}>
                        <div
                          key={index}
                          className={
                            letterDiv.props["data-vert"]
                              ? styles.allInputContainerVert
                              : styles.allInputContainer
                          }
                        >
                          <div
                            key={index}
                            className={
                              letterDiv.props["data-vert"]
                                ? styles.inputContainerVert
                                : styles.inputContainer
                            }
                          >
                            {letterDiv}
                          </div>
                          {maskDivs[index]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div id="cluebelow" key="cluebelow" className={styles.extraMobile}></div>

        </div>
      </div>

      <div key="2" className={styles.mainInputContainerLetterbalk}>
        {arrLettersUsed.map((el, index) => {
          return (
            <div key={index} className={styles.pixelLetterBalk}>
              <div key={index} className={styles.allInputContainerLetterbalk}>
                <input
                  id={"l" + (index + 1)}
                  key={index + 1}
                  type="text"
                  data-masklb={index + 1}
                  className={styles.myInputLetterBalk}
                  onClick={handleLetterbalkClick}
                  readOnly
                />
                <div
                  key={"x" + index + 1}
                  className={styles.divMaskerLetterBalk}
                >
                  {index + 1}
                </div>
              </div>
            </div>
          );
        })}
      </div>


      <div key="1" className={styles.mainInputContainerAlfabet}>
        {alphabet.map((el, index) => {
          return (
            <div
              id={alphabet[index]}
              key={index}
              className={styles.pixelAlfabet}
            >
              {alphabet[index]}
            </div>
          );
        })}
      </div>
      <div key="v1" className={styles.tooltip}>
        <span key="v2" className={styles.tooltiptext}>
          Vul het verticale woord in (aan/uit){" "}
        </span>
        <button key="v3" className={styles.button} onClick={handleVertClick} id={"btnVert"}>
          Verticaal
        </button>
        &nbsp;&nbsp;
      </div>
      <div key="g1" className={styles.tooltip} style={{ display: blnLevels ? '' : 'none' }}>
        <span key="g2" className={styles.tooltiptext}>
          (iets) gemakkelijkere omschrijvingen (aan/uit){" "}
        </span>
        <button key="g3" className={easy ? styles.btnDisabled : styles.button} onClick={handleEasy} id={"btnEasy"}>
          Breinvriendelijk
        </button>
        &nbsp;&nbsp;
      </div>
      <div key="w1" className={styles.tooltip}>
        <span key="w2" className={styles.tooltiptext}>
          Maak het raster leeg en wis de opgeslagen voortgang
        </span>
        <button key="w3" className={styles.button} onClick={handleWissen} id={"btnWis"}>
          Wissen
        </button>
        &nbsp;&nbsp;
      </div>
      <div key="c1" className={styles.tooltip}>
        <span key="c2" className={styles.tooltiptext}>
          Controleer je oplossingen
        </span>
        <button key="c3" className={styles.button} onClick={handleControle} id={"btnControle"}>
          Controleer
        </button>
        &nbsp;&nbsp;
      </div>
      <div key="oa1" className={styles.tooltip}>
        <span key="oa2" className={styles.tooltiptext}>
          Alle cijfervakjes worden ingevuld
        </span>
        <button key="oa3" className={styles.button} onClick={handleOplossingA} id={"btnOplossingA"}>
          Oplossing A
        </button>
        &nbsp;&nbsp;
      </div>
      <div key="ob1" className={styles.tooltip}>
        <span key="ob2" className={styles.tooltiptext}>
          Alle vakjes worden ingevuld
        </span>
        <button key="ob3" className={styles.button} onClick={handleOplossingB} id={"btnOplossingB"}>
          Oplossing B
        </button>
        &nbsp;&nbsp;
      </div>

    </>
  );
};

export default Puzzle;
