import { ChangeEventHandler, MouseEventHandler, useState } from "react";
import styles from "./Input.module.css";
import { NavLink, Redirect, useHistory, Link, useParams } from "react-router-dom";
import { PuzzleTable } from "types";
import { PuzzleParams } from "components/Puzzle/Puzzle";


interface ButtonProps {
    dataPuzzle: PuzzleTable[],
    onInputChange: (table: any) => void
}


const Input = ({ dataPuzzle, onInputChange }: ButtonProps) => {

    const params = useParams<PuzzleParams>();

    dataPuzzle.sort((a, b) => (a.creationdate < b.creationdate) ? 1 : ((b.creationdate < a.creationdate) ? -1 : 0))
    const [table, setTable] = useState<string>(params.key ?? dataPuzzle[0].id);
    setTimeout(() => {              // id in imputlijst aanpassen
        let elOption: any = document.querySelector(`[value='${table}']`)
        elOption.selected = "selected"
    }, 1000);


    const handleChange: ChangeEventHandler<HTMLSelectElement> = (event: any) => {
        setTable(event.currentTarget.value)
    }

    const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
        const sel: any = document.getElementById("selPuzzle")
        onInputChange(table);
        setTable(sel.value);
    }

    const handleVorige: MouseEventHandler<HTMLButtonElement> = (event) => {
        let vorigeID: any = localStorage.getItem("lastPuzzle")
        let elOption: any = document.querySelector(`[value='${vorigeID}']`)
    }


    return (

        <div className={styles.header}>
            <div >
                <div className={styles.container}>
                    <div className={styles.big + " " + styles.tooltip}><span className={styles.tooltiptext}>Bezoek de website</span><a href="https://www.cryptochris.be" target="_blank" rel="noreferrer">CryptoChris</a>&nbsp;</div>
                    <div className={styles.kiesEenPuzzel}>
                        <select name="selectTable" id="selPuzzle" onChange={handleChange} className={styles.inputList} value={table}>
                            {dataPuzzle.map((p: PuzzleTable, index) => {
                                return <option key={index} id={"s" + index.toString()} value={`${p.id}`}>{p.id}&nbsp;&nbsp;&nbsp;&nbsp;{p.creationdate.substring(0, 10)}&nbsp;</option>
                            })}
                        </select>
                        &nbsp;

                    </div>
                    <div className={styles.tooltip}>
                        <span className={styles.tooltiptext}>De geselecteerde puzzel openen</span>
                        <Link to={`/puzzle/${table}`}>
                            <button onClick={handleOpen} className={styles.button}>Open</button>
                        </Link>
                    </div>
                    &nbsp;
                    <div className={styles.tooltip}>
                        <span className={styles.tooltiptext}>De laatst bewerkte puzzel selecteren</span>
                        <Link to="">
                            <button onClick={handleVorige} className={styles.button}>Vorige</button>
                        </Link>
                    </div>
                    &nbsp;
                    <div className={styles.tooltip}>
                        <span className={styles.tooltiptext}>Help bij het gebruik van deze app</span>
                        <button className={styles.button}><a href="https://www.cryptochris.be/online-invullen/" target="_blank" rel="noreferrer" >Help</a></button>
                    </div>
                    &nbsp;
                </div>
            </div>
        </div>
    )
}

export default Input;
